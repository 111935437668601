@font-face {
  font-family: 'Poppins';
  src: url(assets/fonts/Poppins-ExtraLight.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(assets/image/fleur_3.svg),
    url(assets/image/fleur_2.svg);
  background-position: center right, top left;
  background-size: 20%, 20%;
  background-repeat: repeat-y, repeat-y;


}