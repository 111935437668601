@import '../../../shared/colors.scss';

.container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(180deg, #57386B 0%, #160E1B 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__scroll-action {
        margin: 8px;

        img {
            width: 50px;
            cursor: pointer;
        }
    }

    &__logo {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        &__text {
            position: relative;
            color: $neutral;
            font-size: 94px;

            &__1 {
                top: 33%;
                left: -75%;
                position: absolute;
            }

            &__2 {
                top: 40%;
                left: 33%;
                font-size: 3.5rem;
                position: absolute;
            }

            &__column {
                position: absolute;
                top: 33%;
                left: 100%;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &__divider {
                    display: block;
                    height: 2px;
                    width: 235px;
                    background-color: #E9AC18;
                }

                &__sub-title {
                    margin-top: 15px;
                    font-size: 38px;
                    letter-spacing: 0.145em;
                    text-transform: uppercase;
                }

            }
        }

    }
}